import { app } from "@/main";
import axios from "axios";
import { AUTH_LOGOUT } from "@/store/actions/auth";

axios.defaults.headers.common.Accept = "application/json";

axios.interceptors.request.use(
  (request) => {
    if (request.url) {
      request.url = "http://ec2-54-147-61-67.compute-1.amazonaws.com:3000" + request.url;
      // request.url = "http://localhost:3000" + request.url;
    }
    // const token = app.$store.state.auth.token;
    const token = localStorage.getItem("user-token");

    if (token && token !== "") {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      if (window.location.pathname !== "/auth/login"){
        // localStorage.clear();
        window.location.href = "/auth/login?sessionExpired=true";
      }
    }
    if (response.status === 403) {
      if (window.location.pathname !== "/auth/login"){
        // localStorage.clear();
        window.location.href = "/auth/login?tokenExpired=true";
      }
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      const dataFromServer = error.response.data;
      if (dataFromServer.hasOwnProperty("auth") && !dataFromServer.auth) {
        logout('El token de ingreso a expirado');
      }
      return Promise.reject(dataFromServer);
    }
    return Promise.reject(error.message);
  }
);

const logout = (message) => {
  app.$store.dispatch(AUTH_LOGOUT).then(() => {
    app.$bvToast.toast(message, {
      title: "Error",
      variant: "danger",
      solid: true,
    });
    setTimeout(() => app.$router.push({ name: "Login" }), 3000);
  });
};
