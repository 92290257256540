<template>
  <div class="content-filter">
    <div class="content">
      <b-form class="row" @submit.prevent="search">
        <div class="col-12 col-md-6 m-0 p-2">
          <b-form-group>
            <label class="label-login">Título</label>
            <b-form-input v-model="filter.name"></b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6 m-0 p-2">
          <b-form-group>
            <label class="label-login">Título alternativo</label>
            <b-form-input v-model="filter.RTITLE"></b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 col-md-3 m-0 p-2">
          <b-form-group>
            <label class="label-login">Fecha de resolución</label>
            <date-picker v-model="filter.FRESOLUTION" valueType="format"
              :disabledDate="time => time.getTime() > Date.now()"></date-picker>
          </b-form-group>
        </div>

        <div class="col-12 col-md-3 m-0 p-2">
          <b-form-group>
            <label class="label-login">Tema</label>
            <b-form-input v-model="filter.TEMA"></b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 col-md-3 m-0 p-2">
          <b-form-group>
            <label class="label-login">Tipo de entrada</label>
            <b-form-select v-model="filter.BLOG" :options="[
              { text: '-- Seleccione ', value: null },
              { text: 'Común', value: 'common' },
              { text: 'Emblemático', value: 'emblematic' },
              { text: 'Ejecutivo', value: 'executive' }]">
            </b-form-select>
          </b-form-group>
        </div>

        <div class="col-12 col-md-3 m-0 p-2">
          <b-form-group>
            <label class="label-login">Estado</label>
            <b-form-select v-model="filter.CDESTDO"
              :options="[{ text: 'Activo', value: 'A' }, { text: 'Inactivo', value: 'I' }]"></b-form-select>
          </b-form-group>
        </div>

        <div class="col-12 contenedor-buttons">
          <button type="button" class="btn btn-blue-primary" @click="search">Buscar</button>
          <button type="button" class="btn btn-blue-secondary" @click="onClickEntrada">Nuevo</button>
        </div>
      </b-form>

      <div class="table-container">
        <b-col sm="2" md="2" class="px-0">
          <b-form-group label="Filas" label-for="per-page-select" label-cols-sm="1" label-cols-md="2" label-cols-lg="2"
            label-size="sm" class="mb-0">
            <b-form-select id="per-page-select" v-model="grid.perPage" @change="search" :options="grid.pageOptions"
              size="sm">
            </b-form-select>
          </b-form-group>
        </b-col>

        <div class="mt-3 d-flex justify-content-start align-items-start mb-2">
          <b-pagination input-mode v-model="grid.currentPage" :total-rows="grid.totalRows" :per-page="grid.perPage"
            @change="handlePage" size="sm"></b-pagination>

          <div class="ml-2">
            <b-form-input class="p-0 text-center" style="height: 30px" v-model="grid.currentPage" type="number" min="1"
              :max="grid.totalRows" placeholder="N° de página" @keyup.enter="handlePageUpdate" size="md"></b-form-input>
          </div>
        </div>


        <b-table class="mt-4 user-table responsive-table" head-variant="light" hover :items="grid.items"
          :fields="fields" :busy="grid.isLoading" show-empty>
          <template v-slot:cell(FRESOLUTION)="data">
            <span>{{
              setFechaLocale(data.item.FRESOLUTION)
            }}
            </span>
          </template>

          <template v-if="entrieType == 'jurisprudences'" v-slot:cell(TEMA)="data">
            <span v-html="data.item.TEMA"></span>
          </template>


          <template v-slot:cell(ACCIONES)="data">
            <div class="d-flex justify-content-center" style="gap: 10px;">
              <b-dropdown title="Descargar" id="dropdown-1" toggle-class="text-decoration-none" no-caret
                variant="primary" size="sm">
                <template #button-content>
                  <b-icon icon="download" class="text-white font-weight-bold"></b-icon>
                </template>
                <b-dropdown-item @click="downloadFile(data.item)">
                  <b-icon icon="file-earmark-pdf-fill"></b-icon>
                  RESOLUCIÓN
                </b-dropdown-item>
                <b-dropdown-item @click="viewEntrie(data.item, 1)">
                  <b-icon icon="file-earmark-text-fill"></b-icon> DOC. RESUMEN
                </b-dropdown-item>
                <b-dropdown-item @click="viewEntrie(data.item, 2)">
                  <b-icon icon="file-earmark-pdf-fill"></b-icon> PDF. RESUMEN
                </b-dropdown-item>
              </b-dropdown>
              <b-button title="Editar" size="sm" variant="warning" :disabled="data.item.CDESTDO == 'I'"
                @click="editEntrie(data.item)">
                <b-icon icon="pencil-fill"></b-icon>
              </b-button>
              <b-button title="Eliminar" v-if="role.IDR == 0" size="sm" variant="danger" style="color: white!important"
                @click="deleteEntrie(data.item, data.item.CDESTDO)">
                <b-icon icon="trash-fill"></b-icon>
              </b-button>
            </div>
          </template>

          <template v-slot:table-busy>
            <div class="text-center">
              <b-spinner label="Loading..."></b-spinner>
            </div>
          </template>

          <template v-slot:empty>
            <div class="text-center">
              <p class="mt-3">
                <b-icon icon="info-circle-fill" variant="info" class="mr-2"></b-icon>
                No se encontraron registros
              </p>
            </div>
          </template>
          <template v-slot:cell(CDESTDO)="data">
            <div class="d-flex justify-content-center text-center align-items-center h-100">
              <b-badge style="margin: 5px!important;" :variant="data.item.CDESTDO === 'A' ? 'success' : 'danger'">
                {{ data.item.CDESTDO === 'A' ? 'A' : 'I' }}
              </b-badge>
            </div>
          </template>
        </b-table>
      </div>

      <div class="d-flex justify-content-start align-items-start mb-2">
        <b-pagination input-mode v-model="grid.currentPage" :total-rows="grid.totalRows" :per-page="grid.perPage"
          @change="handlePage" size="sm"></b-pagination>

        <div class="ml-2">
          <b-form-input class="p-0 text-center" style="height: 30px" v-model="grid.currentPage" type="number" min="1"
            :max="grid.totalRows" placeholder="N° de página" @keyup.enter="handlePageUpdate" size="md"></b-form-input>
        </div>
      </div>

      <b-modal v-model="isEntriesModalActive" centered :hide-footer="true" :hide-header="true">
        <entries-modal />
      </b-modal>
    </div>

    <ModalDelete :action="actionDelete" :openDelete="openDelete" :closeHandler="closeHandler" />
    <div v-if="isLoadingArchivo" class="spinner-overlay">
      <b-spinner label="Cargando..." variant="primary"></b-spinner>
    </div>

  </div>
</template>

<script>
import adminEntriesProxy from "../../../proxies/AdminEntriesProxy.js";
import EntriesModal from "./EntriesModal.vue";
import ModalDelete from "../../Modal/ModalDelete.vue";
import AdminEntriesProxy from "../../../proxies/AdminEntriesProxy.js";
import { transalateEntries } from "@/common/TypeEntries.js";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import recursos from "./recursos.js";

export default {
  name: "UsersTable",
  props: {
    entrieType: {
      type: String,
      default: "jurisprudences",
    },
    role: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  components: { EntriesModal, ModalDelete },
  data() {
    return {
      isLoadingArchivo: false,
      isEntriesModalActive: false,
      fields: [
        {
          key: "RN",
          label: "",
        },
        {
          key: "FRESOLUTION",
          label: "Publicación",
          sortable: true,
        },
        {
          key: "TITLE",
          label: "Título",
          class: "w-130",
          sortable: true,
        },
        {
          key: "RTITLE",
          label: `Título alternativo`,
          class: "text-left",
          sortable: true,
          formatter: (value, key, item) => {
            return [null, undefined, "null"].includes(value) ? "" : value;
          },
        },
        {
          key: "TEMA",
          label: "Tema",
          sortable: true,
          class: `${this.entrieType === "jurisprudences" ? "" : "d-none"} pl-5`,
        },
        {
          key: "BLOG",
          label: "Tipo de entrada",
          sortable: true,
          class: "text-center w-130",
          width: "30px",
        },
        {
          key: "CDESTDO",
          label: "Estado",
          sortable: true,
          class: "text-center w-130",
        },
        {
          key: "ACCIONES",
          label: "Acciones",
          class: "text-center w-130",
        }
      ],
      grid: {
        items: [],
        currentPage: 1,
        perPage: 10,
        totalRows: 10,
        isLoading: true,
        pageOptions: [5, 10, 15, 50],
      },
      filter: {
        name: null,
        CDESTDO: 'A',
        BLOG: null,
        FRESOLUTION: null,
        TEMA: null,
        RTITLE: null,
      },
      openDelete: false,
      entrieDelete: {},
    };
  },
  computed: {
    rows() {
      return this.grid.items.length;
    },
  },
  methods: {
    transalateEntries,
    setFechaLocale(FRESOLUTION) {
      try {
        let date = new Date(FRESOLUTION);
        date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        return date.toLocaleDateString("es-PE", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      } catch (error) {
        return "";
      }
    },
    async downloadFile(item) {
      let tipo = 'ENTRADA PRINCIPAL';
      let path = item?.ENTRIEFILE || "";
      this.isLoadingArchivo = true;
      await adminEntriesProxy.downloadFile({
        PATH: path
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          const extension = path.split('.').pop();
          link.setAttribute('download', (item?.TITLE || "") + "-" + tipo + '.' + extension);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al descargar el archivo`, {
            title: "Ocurrió un error al descargar el archivo",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isLoadingArchivo = false;
        });
    },
    onClickEntrada() {
      if (this.entrieType == "legislations") {
        this.$router.push({ name: "LegislationEntries" })
      }

      if (this.entrieType == "jurisprudences") {
        this.isEntriesModalActive = true
      }
    },
    handlePage(page) {
      this.grid.currentPage = page;
      this.getEntries();
    },
    handlePageUpdate() {
      this.getEntries();
    },
    async getEntries() {
      const init = (this.grid.currentPage - 1) * this.grid.perPage;
      const rows = this.grid.perPage;
      this.grid.isLoading = true;

      await adminEntriesProxy.listdata({
        ROWS: rows,
        INIT: init,
        DESC: this.selectedFilter?.name || null,
        CESTDO: this.selectedFilter?.CDESTDO || null,
        BLOG: this.selectedFilter?.BLOG || null,
        FRESOLUTION: this.selectedFilter?.FRESOLUTION || null,
        TEMA: this.selectedFilter?.TEMA || null,
        RTITLE: this.selectedFilter?.RTITLE || null
      }, this.entrieType)
        .then((entries) => {
          this.grid.items = entries;
          this.grid.totalRows = entries?.[0]?.TOTALROWS || 0;
        })
        .catch((e) => { })
        .finally(() => this.grid.isLoading = false);
    },
    async deleteEntrie(entrieDelete, cestado) {
      this.entrieDelete = entrieDelete;
      this.openDelete = true;
    },
    async editEntrie(entrie) {
      if (entrie.BLOG == "common") {
        this.$router.push({ name: "CommonEntriesEdit", params: { id: entrie.ID } });
      }

      if (entrie.BLOG == "emblematic") {
        this.$router.push({ name: "EmblematicEntriesEdit", params: { id: entrie.ID } });
      }

      if (entrie.BLOG == "executive") {
        this.$router.push({ name: "LegislationEntriesEdit", params: { id: entrie.ID } });
      }
    },
    closeHandler() {
      this.openDelete = false;
    },
    async actionDelete() {
      this.openDelete = false;
      this.$bvToast.hide();

      await AdminEntriesProxy.delete(this.entrieDelete.ID)
        .then((response) => {
          const toastMessage = response.MESSAGE;

          this.$bvToast.toast(toastMessage, {
            title: response.STATUS ? "Exito" : "Error",
            variant: response.STATUS ? "primary" : "danger",
            solid: true,
          });

          if (response.STATUS) {
            this.getEntries();
          }
        })
        .catch((err) => {
          this.$bvToast.toast(`Ocurrió un error al eliminar la entrada`, {
            title: "Ocurrió un error al eliminar la entrada",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    search() {
      this.selectedFilter = { ...this.filter };
      this.getEntries();
    },
    async viewEntrie(entrie, type) {
      this.isLoadingArchivo = true;
      try {
        let blog = entrie.BLOG;
        let data = await this.obtenerDatos(entrie.ID);

        if (Object.keys(data).length === 0) return;

        if (['common', 'emblematic'].includes(blog)) {
          if (type == 1) {
            // generar WORD
            this.generateWord(entrie, blog);
          }

          if (type == 2) {
            // generar PDF
            this.createPDF(data, blog);
          }
        }

        if (blog === 'executive') {

        }

      } catch (error) {
        this.$bvToast.toast(`Ocurrió un error al obtener la entrada`, {
          title: "Ocurrió un error al obtener la entrada",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoadingArchivo = false;
      }

    },
    async generateWord(data, tipo) {
      await adminEntriesProxy.getDocument({
        ID: data.ID
      })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${data.TITLE.toUpperCase()} - RESUMEN EJECUTIVO.docx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.$bvToast.toast(`Ocurrió un error al obtener el archivo`, {
            title: "Ocurrió un error al obtener el archivo",
            variant: "danger",
            solid: true,
          });
        })
    },
    async createPDF(data, tipo) {

      try {
        let margin = [40, 10, 40, 10];
        let totalPages = 0;
        let fontSize = 11;
        let documentoPDF = {

          header: (currentPage, pageCount) => {
            return {
              style: 'headerStyle',
              columns: [
                { width: '*', text: '', alignment: 'center', margin: [40, 40, 40, 40] },
                {
                  width: 'auto',
                  stack: [
                    {
                      image: recursos.nuevoLogoJuris,
                      width: 60,
                      link: 'http://web-juris-search-caro.s3-website-us-east-1.amazonaws.com/',
                      alignment: 'center',
                      margin: [0, 20, 0, 0]
                    },
                  ],
                },
                { width: '*', text: '', alignment: 'center', margin: [40, 40, 40, 40] },
              ],
            }
          },


          background: [
            {
              image: recursos.toIMG,
              width: 620,
              height: 600,
              absolutePosition: { x: 5, y: 150 },
              alignment: 'center',
              opacity: 0.5,
            }
          ],

          content: [
            {
              text: `${data.TITLE}`,
              style: 'header',
              alignment: 'left',
              margin: [40, -20, 40, 10],
              bold: true,
              FontFace: 'Calibri',
            },
            {
              columns: [
                {
                  ul: [
                    `Tipo de Recurso:`,
                    `Delitos:`,
                    `Vinculante:`,
                  ],
                  margin: [margin[0], 0, 0, 0],
                  fontSize,
                  lineHeight: 1.5,
                  width: '35%',
                  FontFace: 'Calibri',
                },
                {
                  ul: [
                    `${data.RECURSO}`,
                    `${data.DELITO}`,
                    `${data.ISBINDING}`,
                  ],
                  margin: [0, 5, margin[0] + 20, 0],
                  fontSize,
                  lineHeight: 1.5,
                  width: '65%',
                  FontFace: 'Calibri',
                },
              ]
            },

            {
              style: 'tableExample',
              table: {
                dontBreakRows: false,
                widths: ['35%', '65%'],
                body: [
                  [
                    {
                      text: 'CONTENIDO',
                      bold: true,
                      colSpan: 2,
                      fontSize,
                      alignment: 'center',
                      margin: [20, 15, 20, 15]
                    },
                    {},
                  ],
                  [
                    {
                      text: 'TEMA',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    this.renderContent(data.TEMA, fontSize, [10, 15, 10, 15]),
                  ],
                  [
                    {
                      text: 'SUBTEMA',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    this.renderContent(data.SUBTEMA, fontSize, [10, 15, 10, 15]),

                  ],
                  [
                    {
                      text: 'PALABRAS CLAVES',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.KEYWORDS,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: 'SÍNTESIS DE LOS FUNDAMENTOS JURÍDICOS RELEVANTES',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    this.renderContent(data.SHORTSUMMARY, fontSize, [10, 15, 10, 15]),
                  ],
                  [
                    {
                      text: 'FUNDAMENTOS JURÍDICOS RELEVANTES',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                      fillColor: '#fff2cc',
                    },

                    {
                      ...this.renderContent(data.RESUMEN, fontSize, [10, 15, 10, 15]),
                      fillColor: '#fff2cc',
                      italics: true,

                    }
                  ],
                  [
                    {
                      text: 'IDENTIFICACIÓN',
                      bold: true,
                      fontSize,
                      colSpan: 2,
                      alignment: 'center',
                      margin: [10, 15, 10, 15],
                    },
                    {},
                  ],
                  [
                    {
                      text: 'ÁMBITO',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.AMBIT,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: 'FECHA DE RESOLUCIÓN',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.FRESOLUTION,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: 'ÓRGANO JURISDICCIONAL',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.OJURISDICCIONAL,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: 'MAGISTRADOS',
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data.MAGISTRATES,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: ['VOTO DEL DESIDENTE\n', {
                        text: 'Voto que discrepa del fallo final adoptado.', fontSize: fontSize - 2, bold: false, italics: true
                      }],
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data?.VDESIDENTE || '-',
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                  [
                    {
                      text: ['VOTO CONCURRENTE\n', {
                        text: 'Voto que disiente de la argumentación jurídica, pero no del fallo final adoptado.',
                        fontSize: fontSize - 1, bold: false,
                        italics: true
                      }],
                      bold: true,
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                    {
                      text: data?.CVOTE || '-',
                      fontSize,
                      margin: [10, 15, 10, 15],
                    },
                  ],
                ],
              },
            }

          ],
          styles: {
            FontFace: 'Calibri',
            headerStyle: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 5],
            },
            tableExample: {
              margin: [margin[0], 10, margin[2], 10],
              FontFace: 'Calibri',
            },
            footer: {
              FontFace: 'Calibri',
              fontSize: 10,
              margin: [40, 50, 40, 10],
            },
          },
          footer: function (currentPage, pageCount) {
            if (currentPage > totalPages) {
              totalPages = currentPage;
            }
            return {
              style: 'footer',
              columns: [
                { width: '*', text: ``, alignment: 'left', color: 'transparent' },
                { width: 'auto', text: 'www.', alignment: 'center', color: 'gray' },
                { width: 'auto', text: 'ccfirma', alignment: 'center', color: '#e81eb2', link: 'https://ccfirma.com/' },
                { width: 'auto', text: '.com', alignment: 'center', color: 'gray' },
                { width: '*', text: `Página ${currentPage} de ${pageCount}`, alignment: 'right' }
              ],
            };
          },

          pageMargins: [40, 100, 40, 80],
        }

        pdfMake.vfs = await pdfFonts.pdfMake.vfs;

        await pdfMake.createPdf(documentoPDF).download((`${data.TITLE} - RESUMEN EJECUTIVO`).toUpperCase() + '.pdf');

      } catch (error) {
        console.log(error)
      }
    },
    async obtenerDatos(id) {
      let data = {}
      await adminEntriesProxy.getPrint({
        ID: id
      })
        .then((response) => {
          let ambit = JSON.parse(response.AMBIT)?.[0]?.LABEL || '';
          ambit = ambit ? ambit.replace(/\s*,/g, ',') : '';
          let magistrados = JSON.parse(response.MAGISTRADOS)?.[0]?.LABEL || '';
          magistrados = magistrados ? magistrados.replace(/\s*,/g, ',') : '';
          let delitos = JSON.parse(response.DELITO)?.[0]?.LABEL || '';
          delitos = delitos ? delitos.replace(/\s*,/g, ',') : '';
          let ojurisdiccional = JSON.parse(response.OJURISDICCIONAL)?.[0]?.LABEL || '';
          ojurisdiccional = ojurisdiccional ? ojurisdiccional.replace(/\s*,/g, ',') : '';
          let recursos = JSON.parse(response.RECURSO)?.[0]?.LABEL || '';
          recursos = recursos ? recursos.replace(/\s*,/g, ',') : '';
          let materias = JSON.parse(response.MATERIA)?.[0]?.LABEL || '';
          materias = materias ? materias.replace(/\s*,/g, ',') : '';


          data = {
            ID: response.ID,
            TITLE: response.TITLE,
            ISBINDING: response.ISBINDING === "1" ? "SI" : "NO",
            AMBIT: ambit,
            FRESOLUTION: response.FRESOLUTION ? new Date(response.FRESOLUTION).toLocaleDateString("es-PE", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }) : '',
            OJURISDICCIONAL: ojurisdiccional,
            MAGISTRATES: magistrados,
            VDESIDENTE: response.VDESIDENTE,
            CVOTE: response.CVOTE,
            ENTRIEFILE: response.ENTRIEFILE,
            ENTRIEFILERESUMEN: response.ENTRIEFILERESUMEN,
            KEYWORDS: response.KEYWORDS?.replace(/\s*,/g, ', '),
            TEMA: response.TEMA,
            SUBTEMA: response.SUBTEMA,
            SHORTSUMMARY: response.SHORTSUMMARY,
            RESUMEN: response.RESUMEN,
            NENTRIEFILERESUMEN: null,
            NENTRIEFILE: null,
            DELITO: delitos,
            RECURSO: recursos,
            MATERIA: materias,
          }

        })
        .catch((error) => {
          data = {}
          this.$bvToast.toast(`Ocurrió un error al obtener la entrada`, {
            title: "Ocurrió un error al obtener la entrada",
            variant: "danger",
            solid: true,
          });
        });

      return data;
    },
    renderContent(content, fontSize, margin) {
      let decodedContent = this.decodeHtmlEntities(content);

      if (Array.isArray(decodedContent)) {
        return {
          ul: decodedContent,
          fontSize,
          alignment: 'justify',
          margin
        };
      }

      return {
        text: decodedContent,
        fontSize,
        alignment: 'justify',
        margin
      };
    },
    decodeHtmlEntities(text) {
      if (text === null) return '';
      text = text.replace(/&[a-z]+;/g, '');
      try {

        if (text.includes('<ul>')) {
          let t = text.split('<li>').map((item) => {
            item = item.replace(/<\/?[^>]+(>|$)/g, '');
            return item;
          }).filter((item) => item.trim() !== '');

          return t;
        }

        return text.replace(/<[^>]*>?/gm, '');
      } catch (error) {
        return text.replace(/<[^>]*>?/gm, '');
      }

    },
  },
  async created() {
    await this.getEntries();
  },
};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #DDE3EB;
$color-light-gray: #dfdfdf;
$color-gray: #4f4f4f;

.buttom {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 32px;
  background: $color-white-gray;
  min-width: 160px;
  margin: auto;
}

.content-filter {
  .content {
    .form-control {
      border-radius: 5px;
      height: 38px;
    }

    .btn-blue {
      background: $color-light-blue;
      border-radius: 50px !important;
      color: white;
      max-width: 180px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.contenedor-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>