<template>
  <div>
    <div class="main-about fade-in">
      <div class="container  mt-5">
        <h1 class="title">
          El mejor buscador de jurisdicción y legislación peruana
        </h1>
        <p class="subtitle">
          Somos un servicio completo y fácil de usar de Caro & Asociados. Disfruta
          de tener tus documentos siempre a la mano.
        </p>
        <div class="content-img"></div>
      </div>

      <div class="content-about-text">
        <div class="container">
          <p>
            Nos especializamos en Derecho Penal Económico y de la Empresa, así
            como en Compliance. Hace quince años, comenzamos nuestras actividades
            con el objetivo de mejorar los estándares de práctica y los niveles de
            satisfacción del cliente.
            <br />
            <br />
            Nuestra larga experiencia explica nuestra alta tasa de éxito. Creemos
            en la integridad empresarial, lo que nos ha permitido celebrar ser la
            primera empresa en Perú certificada con el Sistema de Gestión
            Antisoborno ISO 37001: 2016, por BASC.
          </p>
        </div>
      </div>

      <div class="content-benefits">
        <div class="container">
          <h1 class="title">Beneficios principales</h1>
          <div class="row justify-content-center">
            <div class="col-lg-4">
              <img src="@/assets/icons/like.svg" />
              <p class="benefits-title">Fácil de usar</p>
              <p class="benefits-subtitle">Interfaz sencilla y entendible</p>
            </div>

            <div class="col-lg-4">
              <img src="@/assets/icons/reload.svg" />
              <p class="benefits-title">Siempre actualizado</p>
              <p class="benefits-subtitle">
                Nuestro equipo siempre está al día y así tú también
              </p>
            </div>
            <div class="col-lg-4">
              <img src="@/assets/icons/resumen.svg" />
              <p class="benefits-title">Resumenes claros</p>
              <p class="benefits-subtitle">Los mejores conceptos profesionales</p>
            </div>
            <div class="col-lg-4">
              <img src="@/assets/icons/star.svg" />
              <p class="benefits-title">Tus favoritos</p>
              <p class="benefits-subtitle">
                Ten a la mano tus documentos más útiles
              </p>
            </div>
            <div class="col-lg-4">
              <img src="@/assets/icons/adapt.svg" />
              <p class="benefits-title">Adaptabilidad</p>
              <p class="benefits-subtitle">
                Disfruta de la misma gran experiencia desde tu laptop o smartphone
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="content-price">
        <div class="container">
          <div class="price-title">
            No lo pienses más y sé parte de <span class="juris-search">Juris</span><span
              class="search-juris">Search</span>
          </div>

          <div class="carousel-container-l relative overflow-hidden mt-10">
            <button @click="prev" class="btn-prev">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-left" width="20"
                height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M15 6l-6 6l6 6" />
              </svg>
            </button>

            <div class="d-flex transition-transform duration-500">
              <div v-for="(item, index) in carouselItems" :key="index" class="carousel-item-l flex-none w-1/3 p-2">
                <div class="card card-item bg-white p-4 rounded-lg">
                  <h3 class="text-lg font-bold">{{ item.DESCRIPCION }}</h3>
                  <p>Precio: {{ item.PRECIO }}</p>
                  <p>{{
                    item?.TIEMPO <= 1 ? "1 mes" : item?.TIEMPO + " meses" }}</p>
                      <div class="content-button">
                        <button @click="toggleModal" class="btn btn-primary">Contratar</button>
                      </div>
                </div>
              </div>
            </div>

            <button @click="next" class="btn-next">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="20"
                height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 6l6 6l-6 6" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <register :openModal="registerModal" :toggleModal="toggleModal" :search="() => { }" />
  </div>
</template>

<script>
import Register from "@/views/auth/Register.vue";
import MantenimientoProxy from '../../proxies/MantenimientoProxy';

export default {
  name: "About",
  directives: {
  },
  components: {
    Register
  },
  data() {
    return {
      currentIndex: 0,
      carouselItems: [],
      registerModal: false,
    };
  },
  methods: {
    closeModalRegister() {
      this.registerModal = false;
    },
    toggleModal() {
      this.registerModal = !this.registerModal;
    },
    next() {
      if (this.currentIndex < this.carouselItems.length - 3) {
        this.currentIndex += 1;
      }
    },
    prev() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1;
      }
    }
  },
  mounted() {
    MantenimientoProxy.listPlanesAbout({
      ROWS: 1000,
      INIT: 0,
      DESC: null
    }
    ).then((response) => {
      response = response.map((item) => {
        item.PRECIO = item.PRECIO.toFixed(2);
        item.TIEMPO = Number((item.TIEMPO / 30).toFixed(0));
        return item;
      });

      this.carouselItems = response;
    })
      .catch(() => { });
  },

};
</script>

<style lang="scss" scoped>
$color-light-blue: #0673f2;
$color-dark: #00171f;
$color-white: #ffffff;
$color-white-gray: #f3f4f4;
$color-light-gray: #f3f4f4;
$color-gray: #4f4f4f;
$color-gray-main: #486169;

.carousel-container-l {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}


.carousel-item-l {
  gap: 200px !important;
  min-width: 33.3333%;
  height: 100%;
}

@media (max-width: 768px) {
  .carousel-container-l {
    max-width: 100%;
    padding: 0 20px;
  }

  .carousel-item-l {
    min-width: 50%;
  }
}

.juris-search {
  color: #e81eb2ff;
}

.search-juris {
  color: #1660f5ff;
}

.btn-prev,
.btn-next {
  background-color: transparent;
  color: #ffffff;
  padding: 10px 20px;
  position: absolute;
  border: none;
  top: 50%;
  transform: translateY(-50%);
}

.card-item {
  width: 100%;
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.btn-prev {
  left: 0;
  z-index: 111;
}

.btn-next {
  right: 0;
  z-index: 111;
}


.main-about {
  background-color: $color-white;
  padding-top: 100px;

  .container {
    .title {
      text-align: center;
      font-family: Inter-SemiBold;
      line-height: 39px;
      font-size: 40px;
      color: $color-light-blue;
      max-width: 680px;
      margin: 0 auto;
      margin-bottom: 40px;
    }

    .subtitle {
      font-size: 15px;
      margin-bottom: 80px;
      font-family: Inter-Regular;
      color: $color-dark;
      font-family: Inter-Regular;
      text-align: center;
      max-width: 680px;
      margin: 0 auto;
      margin-bottom: 40px;
    }

    .content-img {
      margin-bottom: 20px;
    }

    @media (max-width: 768px) {
      padding-top: 60px;

      .title {
        font-size: 32px;
      }

      .subtitle {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }

  .content-about-text {
    background: $color-white-gray;
    padding: 60px 150px;
    width: 100%;

    p {
      text-align: center;
      font-size: 18px;
      font-family: Inter-Regular;
    }

    @media (max-width: 768px) {
      padding: 40px 20px;

      .container {
        padding-top: 10px;

        p {
          font-size: 16px;
        }
      }
    }
  }

  .content-benefits {
    background: $color-white;
    padding: 150px 150px;

    .container {
      .title {
        text-align: center;
        font-weight: bold;
        font-family: Inter-SemiBold;
        line-height: 39px;
        font-size: 32px;
        color: $color-dark;
        max-width: 680px;
        margin: 0 auto;
        margin-bottom: 40px;
      }

      .col-lg-4 {
        text-align: center;
        margin-bottom: 40px;

        img {
          margin-bottom: 20px;
        }

        .benefits-title {
          color: $color-dark;
          font-size: 18px;
          font-family: Inter-Bold;
          margin-bottom: 8px;
        }

        .benefits-subtitle {
          color: $color-dark;
          font-size: 16px;
          font-family: Inter-Regular;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 40px 20px;

      .container {
        .title {
          font-size: 24px;
        }

        .col-lg-4 {
          .benefits-title {
            font-size: 16px;
          }

          .benefits-subtitle {
            font-size: 14px;
          }
        }
      }
    }
  }

  .content-price {
    background: $color-white-gray;
    padding: 60px 150px;
    width: 100%;
    text-align: center;

    .price-title {
      font-size: 32px;
      color: $color-dark;
      margin-bottom: 50px;
      font-family: Inter-Bold;
    }

    .price {
      font-size: 56px;
      color: $color-light-blue;
      margin-bottom: -10px;
      font-family: Inter-ExtraBold;
    }

    .price-subtitle {
      color: $color-gray-main;
      font-size: 24px;
      margin-bottom: 50px;
      font-family: Inter-Bold;
    }

    .content-button {
      button {
        background: $color-light-blue;
        color: $color-white;
        font-size: 15px;
        border-radius: 36px;
        height: 44px;
        padding: 10px 16px;
        font-family: Inter-Regular;
        margin-bottom: 30px;
        margin-top: 30px;
      }
    }

    @media (max-width: 768px) {
      padding: 40px 20px;

      .container {
        .price-title {
          font-size: 24px;
        }

        .price {
          font-size: 40px;
        }

        .price-subtitle {
          font-size: 18px;
          margin-bottom: 10px;
        }

        .content-button {
          button {
            font-size: 14px;
            height: 40px;
            padding: 10px 16px;
          }
        }
      }
    }
  }
}
</style>